<template>
  <v-app-bar
    flat
    color="white">
    <div
      class="btn-wrap"
      @click="$router.back()">
      <span v-if="title"> {{ title }}</span>
      <v-btn
        fab
        small
        depressed
        color="transparent"
        ><v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>
<script>
  export default {
    // we don't have this future in mobile I think we don't need mixin for this
    props: {
      title: {
        type: String,
      },
    },
  };
</script>
<style>
  .v-toolbar__content {
    padding-inline: 5px;
  }
</style>
<style lang="scss" scoped>
  ::v-deep .btn-wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
    > span {
      display: inline-block;
      color: var(--v-primaryColor-base);
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      order: 2;
      + .v-btn {
        &::before {
          display: none;
        }
      }
    }
  }
</style>
